import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * Use `useGetAQuoteHook` within `GetAQuote` component to provide support to logic around the FAB and drawer
 */
export const useGetAQuoteHook = () => {
  const location = useLocation();
  const [isGetAQuoteButtonVisible, setIsGetAQuoteButtonVisible] =
    useState(false);
  const [isGetAQuoteDrawerVisible, setIsGetAQuoteDrawerVisible] =
    useState(false);

  // Lock body scroll when drawer is open
  useEffect(() => {
    document.body.style.overflow = isGetAQuoteDrawerVisible ? "hidden" : "auto";
  }, [isGetAQuoteDrawerVisible]);

  const isHomepage = useMemo(() => location.pathname === "/", [location]);

  // This effect controls the button visibility according to the following criteria:
  // - If path is "/": Button will be hidden if the scroll haven't reached the home services container.
  // - All paths: Button will be hidden if footer is in viewport.
  useEffect(() => {
    const homeServicesElement = document.querySelector("#home-services");
    const footerElement = document.querySelector("#footer");

    const handleGetAQuoteVisibility = () => {
      if (!footerElement) return;
      const { top: footerTop } = footerElement.getBoundingClientRect();
      const isFooterVisible = footerTop - window.innerHeight <= 0;

      if (isHomepage) {
        if (!homeServicesElement) return;
        const { top: containerTop } =
          homeServicesElement.getBoundingClientRect();
        const isHomeServicesElementScrolled = containerTop <= 0;
        setIsGetAQuoteButtonVisible(
          !isFooterVisible && isHomeServicesElementScrolled
        );
        return;
      }

      setIsGetAQuoteButtonVisible(!isFooterVisible);
    };

    handleGetAQuoteVisibility();

    window.addEventListener("scroll", handleGetAQuoteVisibility);

    return () => {
      window.removeEventListener("scroll", handleGetAQuoteVisibility);
    };
  }, [isHomepage]);

  return {
    setIsGetAQuoteDrawerVisible,
    setIsGetAQuoteButtonVisible,
    isGetAQuoteButtonVisible,
    isGetAQuoteDrawerVisible,
  };
};
