import React from 'react';
import { Galleryv2 } from './style';
import { Link } from 'react-router-dom';
const Galeryv2 = ({gallery,type}) => {
    return ( 
        <Galleryv2>
            {
                gallery.map((val,index)=>{
                    return(
                            <div key={index}>
                                {
                                    val.type == 'interactive' &&
                                    <a key={index} target='_blank' href={val.path}><img src={val.url} alt=""/></a>
                                }
                                {
                                    val.type == 'imagen' &&
                                    <Link key={index} to={`/${type}/${val.path}`}>
                                        <img src={val.url} alt=""/>
                                    </Link>
                                }
                            </div>
                    )
                })
            }
        </Galleryv2>
     );
}
 
export default Galeryv2;