import React from "react";
import { GetAQuoteButton } from "./styled";
import { GetAQuotePopover } from "./GetAQuotePopover";
import { useGetAQuoteHook } from "./useGetAQuoteHook";

/**
 * `GetAQuote` displays a FAB which show up a drawer from where to send a brief contact form.
 * By default the button is display
 */
export const GetAQuote = () => {
  const {
    setIsGetAQuoteDrawerVisible,
    isGetAQuoteButtonVisible,
    isGetAQuoteDrawerVisible,
  } = useGetAQuoteHook();

  const handleGetAQuoteButtonClick = () => setIsGetAQuoteDrawerVisible(true);
  const handleGetAQuoteCloseDrawer = () => setIsGetAQuoteDrawerVisible(false);

  return (
    <>
      <GetAQuoteButton
        onClick={handleGetAQuoteButtonClick}
        opacity="true"
        weight
        size="true"
        font
        $isVisible={isGetAQuoteButtonVisible}
      >
        Get a Quote
      </GetAQuoteButton>
      <GetAQuotePopover
        isVisible={isGetAQuoteDrawerVisible}
        onClose={handleGetAQuoteCloseDrawer}
      />
    </>
  );
};
