import React from 'react';
import { ContentPortafolio } from '../../Styles/Styles';
import { Link, useLocation} from "react-router-dom";
const RenderingPortafolio = ({gallery}) => {
    let {pathname} = useLocation();
    return ( 
        <ContentPortafolio>
            {
                gallery.map((val,index)=>{
                   return(
                        <React.Fragment key={index}>
                            {
                                val.link &&
                                <Link to={val.link}>
                                    <img src={val.url} alt="" />
                                </Link>
                            }
                            {
                                !val.link &&
                                <img src={val.url} alt="" />
                            }
                            {
                                pathname == '/Photorealistic-Rendering-Services' &&
                                <div>
                                    <h3>Project Name: {val.name}</h3>
                                    <h3>Location: {val.location}</h3>
                                    <h3>Usage: {val.usage}</h3>
                                    <h3>Tags: {val.tags}</h3>
                                </div>
                            }
                            
                        </React.Fragment>
                   )
                })
            }
        </ContentPortafolio>
     );
}
 
export default RenderingPortafolio;