import React from 'react';

const UnderMaintenance = (props) => {
    return ( 
        <div className='imagen-temp'>
            {
                props.white &&       
                <img src="/assets/img/UnderMaintenance.jpg" alt="" />         
            }
            {
                props.black &&
                <img src="/assets/img/UnderMaintenance2.jpg" alt="" />
            }
            
        </div>
     );
}
 
export default UnderMaintenance;
