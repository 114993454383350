import React, { useState } from 'react';
import { GaleryGrid } from './style';
import Skeleton from 'react-loading-skeleton'
const Galery = ({id, gallery, handlePortafolio}) => {
    return ( 
        <GaleryGrid>
                {
                    gallery.map((val)=>{
                        return(
                            <div key={`${id}-${val.id}`}>
                                <button onClick={()=>handlePortafolio(val.id,true)}>
                                    <Image src={val.url} alt="" />
                                </button>
                            </div>
                        )
                    })
                }
        </GaleryGrid>
     );
}

const Image = ({src}) => {
    const [isLoaded, setIsLoaded] = useState(false)
    return(
        <>
        { !isLoaded &&
            <Skeleton baseColor='#c5c3c3' className='skeleton-image' width={'100%'}></Skeleton>
        }
         <img onLoad={()=>{
            setIsLoaded(true)}} src={src} alt="" style={{display: !isLoaded ? 'none' : 'block'}}/>
        </>
    )
}
 
export default Galery;