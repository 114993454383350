import React, { useState, useEffect } from 'react';
import FrontPage from '../../Components/Services/FrontPage';
import Filtros from '../../Components/Services/Filtros';
import ConceptService from '../../Components/Services/ConceptService';
import Galeryv2 from '../../Components/Services/Galeryv2';
import UnderMaintenance from '../UnderMaintenance';
import useDocumentTitle from '../../Components/UseDocumentTitle';
const Branding = () => {
    const galeries = [
        {
            name : 'economy',
            url : 
                [
                    {
                        url : '/assets/img/Services/Galeria/Branding/Photo-2.png'
                    },
                    {
                        url : '/assets/img/Services/Galeria/Branding/Photo-1.png'
                    },
                    {
                        url : '/assets/img/Services/Galeria/Branding/Photo.png'
                    }
                ]
        },
    ]
    const [galery, setGalery] = useState(galeries[0])
    const [path, setPath] = useState(window.location.pathname)
    useDocumentTitle("Branding | 3D World renderings, Inc.")
    useEffect(()=>{
        window.scrollTo(0, 0)
        if (window.innerWidth  > 1024) {
            const activeNot = document.getElementsByClassName('activeService');
            if(activeNot.length == 1){
                activeNot[0].classList.remove('activeService')
            }

            const active = document.getElementById(path.split("/").join(''))
            active.classList.add('activeService')
            const active2 = document.getElementById('services')
            active2.classList.add('activeli')
        }
    },[])
    return ( 
        <div className='body-content'>
        <FrontPage  portada={'/assets/img/services/branding.png'} name={'Branding'} subtitule={'Services'}></FrontPage>
        <ConceptService>
            <div className='title'>
                <h2>Our Branding</h2>
                <h3>Services</h3>
            </div>
            <p className='branding'>
                Branding is an indispensable part of your real estate development and marketing ventures. That’s why we’ve created our own brand development method composed of four frameworks (Business, Creative, Narrative and Graphic) in order to find the best strategic approach suited to your goals. Whether you’re launching a new real estate development in the city that wants to stand out in the crowd or simply in need of a marketing tool for a new listing that’s about to go live, we create tailor-made brands and strategies for a luxury real estate market that are essential in pitching an idea, raising capital, and ensuring your project’s development.
            </p>
        </ConceptService>
        <UnderMaintenance white={true}></UnderMaintenance>
        {/* <Filtros>
            <ul>
                <li className='active'>All</li><div></div>
                <li>Real Estate</li><div></div>
                <li>Start-Up</li><div></div>
                <li>Marketing Material</li>
            </ul>
        </Filtros> */}
        {/* <Galeryv2 galery={galery}></Galeryv2> */}
    </div>
     );
}
 
export default Branding;