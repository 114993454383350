import React, { useState,useEffect } from 'react';
import FrontPage from '../../Components/Services/FrontPage';
import Filtros from '../../Components/Services/Filtros';
import ConceptService from '../../Components/Services/ConceptService';
import GaleryVideo from '../../Components/Services/GaleryVideo';
import { galeriesVFX } from '../../utils/portaforio';
import useDocumentTitle from '../../Components/UseDocumentTitle';
import { Link, useLocation } from "react-router-dom";
const VFX = () => {

    const [gallery, setGallery] = useState(galeriesVFX)
    let {pathname} = useLocation();
    useDocumentTitle("VFX | 3D World renderings, Inc.")
    const filtrosVFX = [
        {
            title: 'All',
            link: '/VFX-Services'
        },
        {
            title: 'Commercial',
            link: '/VFX-Commercial-Services'
        },
        {
            title: 'Social Media',
            link: '/VFX-Social-Media-Services'
        }
    ]
    const filtro = filtrosVFX.filter((filtro => filtro.link == pathname))
    useEffect(()=>{
        if(pathname=='/VFX-Services'){
            setGallery(galeriesVFX)
        }else{
            setGallery(galeriesVFX.filter((filtro => filtro.category == pathname)))
        }
   })
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    return ( 
        <div className='body-content'>
            <FrontPage alt={'VFX Services - 3D World renderings, Inc'}  portada={'/assets/img/services/Galeria/VFX/portada.jpg'} name={'VFX'} subtitule={'Services'}></FrontPage>
            <ConceptService>
                <div className='title'>
                    {
                        filtro.map((value, index)=>{
                            return (
                                <>
                                    {
                                        value.link == "/VFX-Services" &&
                                        <h2 key={index}>Our VFX</h2>
                                    }
                                    {
                                        value.link !== "/VFX-Services" &&
                                        <h2 key={index}>Our {value.title}</h2>
                                    }
                                </>
                                
                                
                            )
                        })
                   }
                    <h3>Services</h3>
                </div>
                <p className='vfx'>
                    Our Visual Effects or VFX service will help you create, modify, or enhance visual content by creating 3D models and animations, compositing footage, creating special effects, and more. Our team will collaborate closely with you to fully understand your needs and objectives. With this knowledge, we employ our technical know-how and advanced software tools to craft top-notch visual content that caters exclusively to your needs. Our VFX service will allow you to enhance your project’s visual appeal and value whether it be for a commercial, social media campaign, real estate presentation, or website - we’ve got you covered.
                </p>
            </ConceptService>
            <Filtros>
                <ul>
                    {
                        filtrosVFX.map((val,index)=>{
                            return(
                                <>
                                    {
                                    pathname == val.link &&
                                        <>
                                            <li key={index} className='active'><Link to={val.link}>{val.title}</Link></li><div></div>
                                        </>
                                    }
                                    {
                                        pathname != val.link &&
                                        <>
                                            <li key={index}><Link to={val.link}>{val.title}</Link></li><div></div>
                                        </>
                                    }
                                </>
                                
                            )
                        })
                    }
                </ul>
            </Filtros>
            <GaleryVideo type='VFX' gallery={gallery}></GaleryVideo>
        </div>
     );
}
 
export default VFX;