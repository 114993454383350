import React, { Children } from 'react';
import { Concepto } from './style';
const ConceptService = (props) => {
    const {children} = props;
    return ( 
        <Concepto>
            {children}
        </Concepto>
     );
}
 
export default ConceptService;