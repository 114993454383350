import React, { useEffect, useState } from 'react';
import { Galleryv2 } from './style';
import VideoPlayer from '../VideoPlayer';
import { Link } from 'react-router-dom';
const GaleryVideo = ({gallery,type}) => {
    const [width, setWidth] = useState(1920)
    const [height, setHeight] = useState(1060)

    useEffect(()=>{
        if (window.innerWidth  <= 1024) {
            setWidth(800)
            setHeight(500)
        }
    },[])

    window.addEventListener('resize', function(event){
        if (window.innerWidth  <= 1024) {
            setWidth(800)
            setHeight(500)
        }
    });
    return ( 
        <Galleryv2>
            {
                gallery.map((val,index)=>{
                    return(
                        <div key={index} className='height_video'>
                            <Link to={`/${type}/${val.path}`}></Link>
                            <VideoPlayer  URL={val.url} VIDEO_WIDTH={width} VIDEO_HEIGHT={height} mute={true}></VideoPlayer>
                        </div>
                    )
                })
            }
        </Galleryv2>
     );
}
 
export default GaleryVideo;