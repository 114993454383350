import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios'

const PaginationBlog = (props) => {
    const [page, setPage] = React.useState(1);

    const handleChange = (event, value) => {
        setPage(value);
        //props.setCurrentPage(value)
        axios({
          method: 'get',
          //url: 'https://panelcontrol.davivir.com.mx/proyectoslist',
           url: `https://panelblog.3dworld.nyc/blog/list?page=${value}`,
      }).then(function (response) {
          props.setArticles(response.data.data);
          window.scrollTo(0, 0)
          //console.log(response.data,'respuesta')
          // moment.locale('es');
          // setFecha(moment(response.data[0].created_at).format('ll')) 
      });
      };
    return ( 
        <div className='contento-pagination'>
            <Stack spacing={2}>
                <Pagination count={props.paginas} onChange={handleChange} />
            </Stack>
        </div>
     );
}
 
export default PaginationBlog;