import React from 'react';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer, MapConsumer } from "react-leaflet";
const Mapa = () => {
    const position = [20.531615317587555, -100.42565470117405]
    
    let DefaultIcon = L.icon({
        iconSize: [46,56],
        iconUrl: "/assets/img/marker-maps-artelia.png",
        //shadowUrl: iconShadow,
    });

    L.Marker.prototype.options.icon = DefaultIcon;
    return ( 
        <div>
            <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
                <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
            </Marker>
            </MapContainer>
        </div>
     );
}
 
export default Mapa;