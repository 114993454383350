import React from 'react';
import styled from 'styled-components';



const Portada = styled.div`
    width: 100%;
    height: 500px;
    position: relative;
    @media (min-width:1600px) {
        height: 600px;
    }
    .background-black{
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;

    }
    .title{
        position: absolute;
        top: 45%;
        left: 90px;
        display: flex;
        @media (max-width: 1024px) {
            left: 40px;
        }
        @media (max-width: 550px) {
            top: 30%;
        }
        h1{
            color: white;
            font-size: 60px;
            font-weight: normal;
            font-family: "NeueHaasDisplayMedium";
            padding-right: 20px;
            @media (max-width: 550px) {
                font-size: 33px;
                padding-right: 10px;
            }
        }
        h2{
            color: white;
            font-size: 60px;
            font-weight: normal;
            font-family: "HelveticaNeue";
            padding: 0;
            @media (max-width: 550px) {
                font-size: 33px;
            }
        }
    }

`
const Concepto = styled.div`
    width: 100%;
    margin-top:  1.75rem;
    padding: 120px 100px;
    background-color: #F1F0EB;
    @media (max-width: 1024px) {
        padding: 40px;
        margin-top:  1.5rem;
    }
    .title{
        display: flex;
        @media (max-width: 550px) {
            flex-direction: column;
        }
        h2{
            padding: 0 0 25px 0;
            color: black;
            font-family: "NeueHaasDisplayMedium";
            display: flex;
            flex-direction: column;
            @media (max-width: 550px) {
                padding: 0 0 15px 0;
            }
            &::after{
                content: ' ';
                margin-top: 10px;
                width: 100px;
                height: 2px;
                background-color: black;
            }

        }
        h3{
            color: black;
            font-family: "HelveticaNeue";
            font-weight: 300;
            padding-left: 20px;
            @media (max-width: 550px) {
                padding-left: 0;
                padding:0 0 20px;
                font-size: 25px;
            }
        }
    }
    p{
        width: 833px;
        color: #6C6C6C;
        @media (max-width: 1024px) {
            width: 100%;
        }
        /* &:nth-child(2){
            margin-bottom: 20px;
        } */
    }
    .about{
        width: 900px;
        @media (max-width: 1024px) {
            width: 100%;
        }
        &:nth-child(2){
            margin-bottom: 20px;
        } 
    }
    .rendering{
        width: 900px;
        &:nth-child(2){
            margin-bottom: 20px;
        } 
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
    .virtualR{
        width: 833px;
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
    .animation{
        width: 900px;
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
    .interactive{
        width: 880px;
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
    .branding{
        width: 895px;
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
    .vfx{
        width: 900px;
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
`

const Filtrado = styled.div`
    width: 100%;
    background-color: #434343;
    padding: 60px;
    @media (min-width: 1600px) {
        padding: 60px;
    }
    @media (max-width: 1335px) {
        padding: 60px;
    }
    @media (max-width: 1024px) {
        padding: 40px;
    }
    @media (max-width: 430px) {
        padding: 20px;
    }
    @media (max-width: 390px) {
        padding: 20px;
    }
    ul{
        width: 1329px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 29px;
        margin: -10px 0px;
        @media (min-width: 1600px) {
            column-gap: 30px;
            width: 1415px;
        }
        @media (min-width: 1800px) {
            column-gap: 49.2px;
            width: 1629px;
        }
        @media (max-width: 1550px) {
            column-gap: 29px;
        }
        @media (max-width: 1485px) {
            column-gap: 22px;
            width: 1260px;
        }
        @media (max-width: 1410px) {
            column-gap: 17px;
            width: 1200px;
        }
        @media (max-width: 1370px) {
            column-gap: 15px;
            width: 1160px;
        }
        @media (max-width: 1335px) {
            column-gap: 14px;
        }
        @media (max-width: 1280px) {
            column-gap: 14px;
            width: auto;
        }
        @media (max-width: 1024px) {
            column-gap: 12px;
            width: auto;
        }
        @media (max-width: 820px) {
            column-gap: 23px;
        }
        @media (max-width: 770px) {
            column-gap: 18px;
        }
        @media (max-width: 550px) {
            column-gap: 7px;
        } 

        word-wrap: break-word;
        align-items: center;
        li{
            color: #787878;
            list-style: none;
            font-size: 35px;
            font-family: "HelveticaNeue";
            display: flex;
            align-items: flex-end;
            line-height: 27px;
            margin: 10px 0;
            word-wrap: break-word;
            padding-bottom: 10px;
            @media (max-width: 1024px) {
                font-size: 27px;
                padding-bottom: 5px;
            }
            @media (max-width: 550px) {
                font-size: 22px;
                padding-bottom: 2px;
                margin: 5px 0;
            }
            &.active{
                color: white;
                text-decoration: none;
                border-bottom: 4px solid #FFFF02;
                font-weight: 700;
                a{
                    color: white;
                }
            }

            a{
                color: #787878;
                list-style: none;
                font-size: 33px;
                font-family: "HelveticaNeue";
                display: flex;
                align-items: flex-end;
                line-height: 27px;
                word-wrap: break-word;
                @media (min-width: 1600px) {
                    font-size: 35px;
                }
                @media (max-width: 1024px) {
                    font-size: 25px;
                }
                @media (max-width: 550px) {
                    font-size: 18px;
                    padding-bottom: 0px;
                    margin: 0;
                }
                @media (max-width: 375px) {
                    font-size: 17px;
                }
                @media (max-width: 360px) {
                    font-size: 16px;
                }
                &:hover{
                        color: white;
                        cursor: pointer;
                    }
            }
        }
        div{
            width: 2px;
            height: 35px;
            background-color:  #787878;
            margin: 0;
            @media (max-width: 1024px) {
                height: 25px;
            }
            @media (max-width: 550px) {
                height: 18px;
                width: 1px;
            } 
        }
    }
`

const GaleryGrid = styled.div`
    columns: 3;
    padding:  1.75rem;
    column-gap:  1.75rem;
    position: relative;
    column-fill: balance;
    @media (max-width: 1024px) {
        columns: 2;
    }
    @media (max-width: 550px) {
        columns: 2;
        column-gap:  1rem;
    }
    div{
        display: inline-block;
        margin-bottom:  1.75rem;
        position: relative;
        width: 100%;
        height: 50%;
        @media (max-width: 550px) {
            margin-bottom:  1rem;
        }
        img{
            width: 100%;
            object-fit: cover;
        }
        .skeleton-image{
            height: 380px;
            @media (max-width: 1024px) {
               height:200px;
            }
            @media (max-width: 550px) {
            height: 120px;
            }
        }
        button{
            width: 100%;
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
    }
    .bottom{
        position: absolute;
        background: white;
        height: 200px;
        bottom: 0;
        left: 0;
        margin: 0;
    }
`
const Galleryv2 = styled.div`
    width: 100%;
    padding:0 0  1.75rem;
    img{
        width: 100%;
        padding:  1.75rem 0 0;
        cursor: pointer;
        @media (max-width: 1024px) {
            padding:  1.5rem 0 0;
            height: 450px;
        }
        @media (max-width:500px) {
            height: 350px;
            object-fit: cover;
        }
    }
    video{
        width: 100%;
        padding:  1.75rem 0 0;
        @media (max-width: 1024px) {
            padding:  1.5rem 0 0;
        }
    }
    .height_video{
        height: 800px;
        padding:  1.75rem 0 0;
        @media (max-width: 1024px) {
            padding:  1.5rem 0 0;
            height: 450px;
        }
        @media (max-width:550px) {
            height: 350px;
        }
        a{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 99999;
        }
        .player-wrapper{
            cursor: pointer;
        }
    }
`
const Gallery360 = styled.div`
    width: 100%;
    padding: 1.75rem 0 0;
    .contenedor_360{
        width: 100%;
        height: 650px;
        padding-bottom: 1.75rem;
    }
    .pnlm-container{
        width: 100% !important;
        height: 100% !important;
    }

    .height_iframe{
        height: 800px;
        padding:  1.75rem 0 0;
        iframe{
            width: 100%;
            height: 100%;
        }
    }
`
const NewsArticles = styled.div`
    width: 100%;
    /* height: 1200px; */
    .content-card{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 40px;
        row-gap: 40px;
        background-color: #F1F0EB;
        margin-top: 1.75rem;
        padding: 50px;
        @media (max-width: 1024px) {
            padding: 40px;
        }
        .card{
            width: 48.5%;
            height: auto;
            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            @media (max-width: 1024px) {
                width: 100%;
            }
        
            .img{
                height: 400px;
                padding-bottom: 15px;
                @media (min-width: 1600px) {
                    height: 470px;
                }
                /* @media (max-width: 550px) {
                    height: 320px;
                } */
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    cursor: pointer;
                }
            }
            a{
                    font-family: HelveticaNeuemedium;
                    font-size: 23px;
                    font-weight: 700;
                    background-color: transparent;
                    color: black;
                    z-index: 1;
                    cursor: pointer;
                    @media (max-width: 550px) {
                        font-size: 18px;
                    }

                }
            .link-subrayado{
                text-decoration: none;
                color: black;
                font-weight: 700;
                vertical-align: top;
                background-image: linear-gradient(transparent 20%, var(--link-2) 10%);
                background-size: auto 175%;
                transition: background 0.5s ease-in-out;
                &:hover{
                    background-position-y: 100%;
                }
            }
        }
    }
`


export {
    Portada,
    Concepto,
    Filtrado,
    GaleryGrid,
    Galleryv2,
    Gallery360,
    NewsArticles
}