import React from 'react';
import { Portada } from './style';

const FrontPage = (props) => {
    return ( 
        <Portada>
            <div className='background-black'></div>
            <img src={props.portada} alt={props.alt} />
            <div className={`title ${props.column}`}>
                <h1>{props.name}</h1><h2>{props.subtitule}</h2>
            </div>
        </Portada>
     );
}
 
export default FrontPage;