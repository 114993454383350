import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination } from "swiper";

const SliderComun = ({children, pagination}) => {
    return ( 
        <Swiper 
            autoplay={{
                delay: 8000,
                disableOnInteraction: false,
            }}
            loop={true}
            pagination={pagination} 
            modules={[Pagination, Autoplay]} 
            className="mySwiper">
            {children}
        </Swiper>
     );
}
 
export default SliderComun;